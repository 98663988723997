<template>
  <div>
    <iframe
      v-if="src"
      :src="src"
      frameborder="0"
      style="width: 98vw; margin-left: 1vw; margin-right: 1vh; height: 86vh"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: "",
    };
  },

  mounted() {
    const { lng, lat } = this.$route.query;
    if (lng && lat) {
      this.src = `https://uri.amap.com/marker?position=${lng},${lat}&callnative=1`;
    }
  },
};
</script>